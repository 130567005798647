<template>
  <b-row>
    <b-col md="12">
      <validation-provider
        #default="{ errors }"
        rules="required"
      >
        <b-form-group
          :label="$t('Responsável')"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <v-select
            v-model="sw030s02"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="formVars.usersCreate"
            append-to-body
            :calculate-position="withPopper"
            :clearable="true"
            label="nome"
            item-text="nome"
            item-value="id"
          >
            <template #option="{ nome }">
              {{ nome }}
            </template>
            <div slot="no-options">
              {{ $t('Sem resultados') }}
            </div>
          </v-select>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import btoa from 'btoa'
import atob from 'atob'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixFormGeral],
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sw030s02: null,
    }
  },
  computed:
  {
    ...mapGetters('seguimentos', ['formVars']),
  },
  mounted() {
    if ('sw030s02' in this.row) {
      this.sw030s02 = this.formVars.usersCreate.find(o => o.id === btoa(this.row.sw030s02)) || ''
    }
  },
  methods: {
    clear() {
      this.sw030s02 = null
    },
    getData() {
      return {
        sw030s02: (('id' in this.sw030s02) ? atob(this.sw030s02.id) : ''),
        nomeResponsavel: (('nome' in this.sw030s02) ? this.sw030s02.nome : ''),
        photoResponsavel: ((('photo' in this.sw030s02) && ('imagem_300' in this.sw030s02.photo)) ? this.sw030s02.photo.imagem_300 : ''),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
